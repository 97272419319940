import { CustomThemedResource } from "@/comps/image"

import { devices, Mobile } from "@/utils/breakpoints"
import { getFormattedDate } from "@/utils/dates"
import { getAnalytics, logEvent } from "firebase/analytics"

import { useTranslation } from "next-i18next"
import styled from "styled-components"

import { JobListing } from "types/general"

import useAuth from "@/utils/client-auth"
import ApplyNow from "./apply"

const Container = styled.a`
  display: flex;
  flex-direction: column;

  background-color: var(--color-background-cell);

  .light &:hover {
    background-color: var(--color-blue-50);
  }

  .dark &:hover {
    background-color: var(--color-blue-50);
  }

  transition: background-color 200ms ease-in-out;

  border-radius: 12px;

  @media ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;

    padding: 29px 30px;
    border-radius: 0;

    & + & {
      border-top: 1px solid var(--color-separator);
    }
  }

  @media ${devices.desktop} {
    padding: 38px 30px;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 12px 24px;

  @media ${devices.tablet} {
    padding: 0;
    gap: 16px;
  }
`

const TitleContainer = styled.h2`
  font-weight: 700;
  line-height: 36px;
  color: var(--color-blue-800);

  font-size: 18px;

  text-align: center;

  @media ${devices.tablet} {
    text-align: left;
    font-size: 20px;
  }

  @media ${devices.desktop} {
    font-size: 28px;
  }
`

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;

  justify-content: center;

  @media ${devices.tablet} {
    justify-content: flex-start;
  }
`

const DetailIcon = styled(CustomThemedResource)`
  height: 24px;
  width: 24px;
`

const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  flex-shrink: 0;
`

const DetailText = styled.span`
  color: var(--color-blue-400);
  text-transform: capitalize;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  @media ${devices.desktop} {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }
`

const LevelContainer = styled.div`
  svg *[fill="#828282"] {
    stroke: var(--color-blue-400);
    fill: var(--color-blue-400);
  }
`

const InternIcon = () => (
  <LevelContainer>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.30078 20.5542H19.699"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52495 8.90332H5.15624C4.68574 8.90332 4.30078 9.28828 4.30078 9.75878V17.1328C4.30078 17.6033 4.68574 17.9883 5.15624 17.9883H6.52495C6.99545 17.9883 7.3804 17.6033 7.3804 17.1328V9.75878C7.3804 9.28828 6.99545 8.90332 6.52495 8.90332Z"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6841 6.17432H11.3154C10.8449 6.17432 10.46 6.55927 10.46 7.02977V17.1327C10.46 17.6032 10.8449 17.9882 11.3154 17.9882H12.6841C13.1546 17.9882 13.5396 17.6032 13.5396 17.1327V7.02977C13.5396 6.55927 13.1546 6.17432 12.6841 6.17432Z"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8433 3.44531H17.4746C17.0041 3.44531 16.6191 3.83027 16.6191 4.30077V17.1326C16.6191 17.6031 17.0041 17.9881 17.4746 17.9881H18.8433C19.3138 17.9881 19.6988 17.6031 19.6988 17.1326V4.30077C19.6988 3.83027 19.3138 3.44531 18.8433 3.44531Z"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </LevelContainer>
)

const JuniorIcon = () => (
  <LevelContainer>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.30103 20.5542H19.6992"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52519 8.90332H5.15648C4.68598 8.90332 4.30103 9.28828 4.30103 9.75878V17.1328C4.30103 17.6033 4.68598 17.9883 5.15648 17.9883H6.52519C6.99569 17.9883 7.38065 17.6033 7.38065 17.1328V9.75878C7.38065 9.28828 6.99569 8.90332 6.52519 8.90332Z"
        fill="var(--color-blue-500)"
        stroke="var(--color-blue-500)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6844 6.17432H11.3157C10.8452 6.17432 10.4602 6.55927 10.4602 7.02977V17.1327C10.4602 17.6032 10.8452 17.9882 11.3157 17.9882H12.6844C13.1549 17.9882 13.5398 17.6032 13.5398 17.1327V7.02977C13.5398 6.55927 13.1549 6.17432 12.6844 6.17432Z"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8438 3.44531H17.4751C17.0046 3.44531 16.6196 3.83027 16.6196 4.30077V17.1326C16.6196 17.6031 17.0046 17.9881 17.4751 17.9881H18.8438C19.3143 17.9881 19.6993 17.6031 19.6993 17.1326V4.30077C19.6993 3.83027 19.3143 3.44531 18.8438 3.44531Z"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </LevelContainer>
)

const MiddleIcon = () => (
  <LevelContainer>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.30103 20.5542H19.6992"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52519 8.90332H5.15648C4.68598 8.90332 4.30103 9.28828 4.30103 9.75878V17.1328C4.30103 17.6033 4.68598 17.9883 5.15648 17.9883H6.52519C6.99569 17.9883 7.38065 17.6033 7.38065 17.1328V9.75878C7.38065 9.28828 6.99569 8.90332 6.52519 8.90332Z"
        fill="var(--color-blue-500)"
        stroke="var(--color-blue-500)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6844 6.17432H11.3157C10.8452 6.17432 10.4602 6.55927 10.4602 7.02977V17.1327C10.4602 17.6032 10.8452 17.9882 11.3157 17.9882H12.6844C13.1549 17.9882 13.5398 17.6032 13.5398 17.1327V7.02977C13.5398 6.55927 13.1549 6.17432 12.6844 6.17432Z"
        fill="var(--color-blue-500)"
        stroke="var(--color-blue-500)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8438 3.44531H17.4751C17.0046 3.44531 16.6196 3.83027 16.6196 4.30077V17.1326C16.6196 17.6031 17.0046 17.9881 17.4751 17.9881H18.8438C19.3143 17.9881 19.6993 17.6031 19.6993 17.1326V4.30077C19.6993 3.83027 19.3143 3.44531 18.8438 3.44531Z"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </LevelContainer>
)

const SeniorIcon = () => (
  <LevelContainer>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.30103 20.5542H19.6992"
        stroke="var(--color-blue-400)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52519 8.90332H5.15648C4.68598 8.90332 4.30103 9.28828 4.30103 9.75878V17.1328C4.30103 17.6033 4.68598 17.9883 5.15648 17.9883H6.52519C6.99569 17.9883 7.38065 17.6033 7.38065 17.1328V9.75878C7.38065 9.28828 6.99569 8.90332 6.52519 8.90332Z"
        fill="var(--color-blue-500)"
        stroke="var(--color-blue-500)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6844 6.17432H11.3157C10.8452 6.17432 10.4602 6.55927 10.4602 7.02977V17.1327C10.4602 17.6032 10.8452 17.9882 11.3157 17.9882H12.6844C13.1549 17.9882 13.5398 17.6032 13.5398 17.1327V7.02977C13.5398 6.55927 13.1549 6.17432 12.6844 6.17432Z"
        fill="var(--color-blue-500)"
        stroke="var(--color-blue-500)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8438 3.44531H17.4751C17.0046 3.44531 16.6196 3.83027 16.6196 4.30077V17.1326C16.6196 17.6031 17.0046 17.9881 17.4751 17.9881H18.8438C19.3143 17.9881 19.6993 17.6031 19.6993 17.1326V4.30077C19.6993 3.83027 19.3143 3.44531 18.8438 3.44531Z"
        fill="var(--color-blue-500)"
        stroke="var(--color-blue-500)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </LevelContainer>
)

export const levelToText = (level: string) => {
  if (level === "Intern") return "Internship"
  if (level === "Junior") return "Junior"
  if (level === "Mid") return "Middle"
  return "Senior"
}

interface LevelProps {
  level: "Intern" | "Junior" | "Mid" | "Senior"
}

const Level = (props: LevelProps) => {
  const { level } = props

  const getIcon = (name: string) => {
    if (name === "Intern") return <InternIcon />
    if (name === "Junior") return <JuniorIcon />
    if (name === "Mid") return <MiddleIcon />
    return <SeniorIcon />
  }
  return (
    <DetailContainer>
      {getIcon(level)}
      <DetailText>{levelToText(level)}</DetailText>
    </DetailContainer>
  )
}

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;

  padding: 16px 21px;

  @media ${devices.tablet} {
    padding: 0;
  }
`

const DeadlineText = styled.span`
  color: var(--color-blue-600);

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  align-self: center;

  @media ${devices.tablet} {
    align-self: flex-end;
  }
`

const Separator = styled.div`
  height: 1px;
  background-color: var(--color-separator);

  justify-self: stretch;
`

interface ListingProps {
  job: JobListing
}

const Listing = (props: ListingProps) => {
  const {
    job: {
      position,
      location,
      employment,
      deadline,
      level,
      slug,
    },
  } = props
  const { userInfo } = useAuth()

  const { t } = useTranslation("common")

  return (
    <Container
      href={"/careers/" + slug}
      onClick={() =>
        logEvent(getAnalytics(), "web_position_apply", {
          slug,
          uid: userInfo.userId,
        })
      }>
      <InfoContainer>
        <TitleContainer>{position}</TitleContainer>
        <DetailRow>
          <Level level={level} />
          <DetailContainer>
            <DetailIcon source="/comps/time" format="svg" />
            <DetailText>
              {t(
                "page-careers:txt_employment_" + employment,
              )}
            </DetailText>
          </DetailContainer>
          <DetailContainer>
            <DetailIcon
              source="/comps/location"
              format="svg"
            />
            <DetailText>{location}</DetailText>
          </DetailContainer>
        </DetailRow>
      </InfoContainer>
      <Mobile>
        <Separator />
      </Mobile>
      <ActionContainer>
        <ApplyNow />
        <DeadlineText>
          {deadline
            ? t("fs_deadline", {
                date: getFormattedDate(deadline),
              })
            : t("txt_no_deadline")}
        </DeadlineText>
      </ActionContainer>
    </Container>
  )
}

export default Listing
