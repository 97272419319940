import Category from "@/comps/careers/positions/category"
import { Heading3 } from "@/comps/typography"
import { devices } from "@/utils/breakpoints"
import { scrollToSection } from "@/utils/scrollto"

import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import styled, { css } from "styled-components"
import { JobListing } from "../../../types/general"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: var(--w-screen);
  background-color: var(--color-background);
`

const Resizer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 0 16px;
  align-self: stretch;

  @media ${devices.tablet} {
    padding: unset;
    align-self: unset;

    width: 605px;
  }

  @media ${devices.desktop} {
    width: 975px;
  }
`

const Title = styled(Heading3)`
  text-align: center;
  padding: 64px 0 80px 0;

  @media ${devices.tablet} {
    padding: 36px 0 16px 0;
  }

  @media ${devices.desktop} {
    padding: 30px 0 30px 0;
  }
`

const MobileDepartmentsScroller = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  gap: 16px;

  padding: 16px;

  background-color: var(--color-background);

  overflow-x: scroll;

  overscroll-behavior-x: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const MobileDepartments = styled.div<{ fixed: boolean }>`
  ${(props) =>
    props.fixed
      ? css`
          position: fixed;
          top: 48px;
          left: 0;
        `
      : css`
          position: absolute;
          top: 100px;
          left: 0;
        `};

  z-index: 50;

  width: var(--w-screen);

  @media ${devices.tablet} {
    display: none;
  }
`

const SingleDepartment = styled.button<{ active: boolean }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  padding: 10px 18px;

  border-radius: 30px;

  transition: background-color 200ms ease-in-out,
    color 200ms ease-in-out;
  ${(props) =>
    props.active
      ? css`
          background-color: var(--color-primary-500);
          color: var(--color-white);
        `
      : css`
          background-color: var(--color-blue-100);
          color: var(--color-blue-600);
        `};
`

interface Departments {
  [key: string]: JobListing[]
}

interface PositionProps {
  jobs: JobListing[]
}

const Positions = (props: PositionProps) => {
  const { t } = useTranslation(["common", "page-careers"])

  const [fixed, setFixed] = useState(false)
  const [active, setActive] = useState(0)

  const departments = useMemo<Departments>(
    () =>
      props.jobs.reduce((acc, job) => {
        if (acc[job.department] === undefined) {
          acc[job.department] = []
        }

        acc[job.department].push(job)

        return acc
      }, {} as Departments),
    [props.jobs],
  )

  const router = useRouter()

  const handleScroll = useCallback(() => {
    const becomeFixed = 782
    const shouldBeFixed = window.scrollY >= becomeFixed

    if (shouldBeFixed !== fixed) setFixed(shouldBeFixed)

    const minimumTop = 120
    const departmentTops = Object.keys(departments).map(
      (tag) =>
        document
          .getElementById(tag.toLowerCase())
          ?.getBoundingClientRect().top,
    )

    const activeDepartment =
      (departmentTops.length -
        1 -
        departmentTops
          .reverse()
          .findIndex((top) => top && top <= minimumTop)) %
      departmentTops.length

    if (activeDepartment !== active)
      setActive(activeDepartment)
  }, [fixed, setFixed, departments, active])

  useEffect(() => {
    addEventListener("scroll", handleScroll)
    handleScroll()

    return () => removeEventListener("scroll", handleScroll)
  }, [handleScroll, router])

  return (
    <Container>
      <Resizer>
        <Title>
          {t("page-careers:txt_careers_heading")}
        </Title>
        <MobileDepartments fixed={fixed}>
          <MobileDepartmentsScroller>
            {Object.entries(departments).map(
              ([department], index) => (
                <SingleDepartment
                  onClick={() => {
                    scrollToSection(
                      department.toLowerCase(),
                    )()
                  }}
                  active={active === index}
                  key={index}>
                  {department}
                </SingleDepartment>
              ),
            )}
          </MobileDepartmentsScroller>
        </MobileDepartments>
        {Object.entries(departments).map(
          ([department, jobs], index) => (
            <Category
              name={department}
              jobs={jobs}
              key={index}
            />
          ),
        )}
      </Resizer>
    </Container>
  )
}

export default Positions
