import { devices } from "@/utils/breakpoints"
import styled, { css } from "styled-components"

export const Heading1 = styled.h1`
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 800;
    font-size: 48px;
    line-height: 57px;
    letter-spacing: 0.02em;
  }
`

export const Heading2 = styled.h2`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 800;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 800;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0.02em;
  }
`

export const Heading3 = styled.h3`
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 800;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 800;
    font-size: 32px;
    line-height: 52px;
    letter-spacing: 0.02em;
  }
`

export const Heading4 = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.02em;
  }
`

export const Heading5 = styled.h5`
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.02em;
  }
`

export const Heading6 = styled.h6`
  @media ${devices.tablet} {
  }

  @media ${devices.desktop} {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
  }
`

export const Title1 = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
  }
`

export const Title2 = styled.span`
  text-transform: uppercase;

  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }
`

export const Body1 = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
  }
`

export const Body2 = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: var(--color-blue-700);

  @media ${devices.tablet} {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;
    letter-spacing: 0.02em;
  }
`

export const Body3 = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;
  }
`

export const Body4 = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
  }
`

export const Body5 = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.02em;
  }
`

export const Caption = styled.span`
  text-transform: uppercase;

  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.08em;

  @media ${devices.tablet} {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.5px;
  }

  @media ${devices.desktop} {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
  }
`

export const buttonStyle = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
  }
`

export const tooltipStyle = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
`
