import Listing from "@/comps/careers/positions/listing"

import {
  DesktopOrTablet,
  devices,
} from "@/utils/breakpoints"

import styled from "styled-components"
import { JobListing } from "../../../types/general"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  align-self: stretch;

  & + & {
    padding-top: 12px;

    @media ${devices.tablet} {
      padding-top: 45px;
    }

    @media ${devices.desktop} {
      padding-top: 70px;
    }
  }

  @media ${devices.desktop} {
    gap: 5px;
  }
`

const Name = styled.h3`
  display: none;

  @media ${devices.tablet} {
    display: block;

    color: var(--color-blue-600);
    text-transform: uppercase;
    padding: 0 16px;

    font-weight: 700;
    line-height: 52px;

    font-size: 20px;
  }

  @media ${devices.desktop} {
    font-size: 24px;
    color: var(--color-blue-600);
  }

  ::selection {
    color: var(--color-blue-600);
  }
`

const Listings = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;

  @media ${devices.tablet} {
    border-radius: 12px;
    gap: 0;
  }

  overflow: hidden;
`

interface CategoryProps {
  name: string
  jobs: JobListing[]
}

const Category = (props: CategoryProps) => {
  const { name, jobs } = props

  return (
    <Container id={name.toLowerCase()}>
      <DesktopOrTablet>
        <Name>{name}</Name>
      </DesktopOrTablet>
      <Listings>
        {jobs.map((job, index) => (
          <Listing job={job} key={index} />
        ))}
      </Listings>
    </Container>
  )
}

export default Category
