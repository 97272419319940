import Benefits from "@/comps/careers/benefits"
import Positions from "@/comps/careers/positions"
import Crisp from "@/comps/crisp"
import Footer from "@/comps/footer"
import Header from "@/comps/header"
import Meta from "@/comps/meta"
import { getServerSideProps } from "@/ssr/careers"

import { useTranslation } from "next-i18next"
import styled, {
  createGlobalStyle,
} from "styled-components"

import { JobListing } from "types/general"

export const GlobalStyle = createGlobalStyle`
	html {
		background: var(--color-background);
	}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--w-screen);

  overflow: hidden;
`

interface CareerProps {
  jobs: JobListing[]
}

const Career = (props: CareerProps) => {
  const { t } = useTranslation("page-careers")

  return (
    <Container>
      <Meta
        title={t("txt_careers_title")}
        description={t("txt_careers_description")}
      />
      <Crisp page="careers" />
      <GlobalStyle />
      <Header hideHiring />
      <Benefits />
      <Positions {...props} />
      <Footer />
    </Container>
  )
}

export { getServerSideProps }

export default Career
