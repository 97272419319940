import { devices } from "@/utils/breakpoints"

import { useTranslation } from "next-i18next"
import styled from "styled-components"

const TickContainer = styled.div`
	& path {
		fill: var(--color-success);
	}
`

const Tick = () => (
	<TickContainer>
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.8872 6.72647C14.2884 6.34694 14.306 5.71402 13.9265 5.3128C13.5469 4.91158 12.914 4.894 12.5128 5.27353L7.83903 9.69466L6.75926 8.43492C6.39983 8.0156 5.76853 7.96704 5.34921 8.32646C4.92988 8.68588 4.88132 9.31718 5.24074 9.73651L7.00401 11.7936C7.18214 12.0015 7.43767 12.1272 7.71102 12.1415C7.98436 12.1558 8.25162 12.0574 8.45046 11.8693L13.8872 6.72647Z"
				fill="#08E69E"
			/>
		</svg>
	</TickContainer>
)

const Item = styled.li`
	list-style: none;

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	font-weight: 500;
	font-size: 18px;
	line-height: 31px;

	@media ${devices.desktop} {
		gap: 16px;
	}
`

interface PointProps {
	text: string
}

const Point = (props: PointProps) => {
	const { text } = props

	const { t } = useTranslation(["page-careers", "common"])

	return (
		<Item>
			<Tick />
			{t(text)}
		</Item>
	)
}

export default Point
