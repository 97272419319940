import { CustomThemedResource } from "@/comps/image"
import { Body2 } from "@/comps/typography"

import {
  DesktopOrTablet,
  devices,
  Mobile,
} from "@/utils/breakpoints"

import { useTranslation } from "next-i18next"
import styled, { css } from "styled-components"

import { assetUrl } from "@/utils/cdn"
import Point from "./point"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--w-screen);
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: var(--w-screen);
  background-color: var(--color-background);

  gap: 12px;
  padding: 22px 0 46px 0;

  @media ${devices.desktop} {
    padding: 54px 0 40px 0;
  }
`

const Title = styled.h1`
  text-align: center;

  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.02em;

  @media ${devices.tablet} {
    font-weight: 800;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.02em;
  }

  @media ${devices.desktop} {
    font-weight: 800;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0.02em;
  }
`

const Subtitle = styled(Body2)`
  text-align: center;

  padding: 0 16px;
`

const CoverContainer = styled.div`
  position: relative;

  width: var(--w-screen);

  overflow: hidden;

  height: 181px;

  @media ${devices.tablet} {
    height: 232px;
  }

  @media ${devices.desktop} {
    height: 280px;
  }
`

const CoverImageBackground = styled.div<{
  light: string
  dark: string
}>`
  width: var(--w-screen);

  height: 181px;

  @media ${devices.tablet} {
    height: 232px;
  }

  @media ${devices.desktop} {
    height: 280px;
  }

  ${(props) => css`
    .light & {
      background-image: url("${props.light}");
    }

    .dark & {
      background-image: url("${props.dark}");
    }
  `}
`

const CoverImageForeground = styled(CustomThemedResource)`
  max-width: unset;

  position: absolute;
  top: 0;

  right: 0;
  height: 181px;

  @media ${devices.tablet} {
    right: -300px;
    height: 232px;
  }

  @media ${devices.desktop} {
    right: -200px;
    height: 280px;
  }
`

const PointsAligner = styled.div`
  width: var(--w-screen);

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;

  width: var(--w-screen);
  padding-top: 32px;

  @media ${devices.tablet} {
    align-items: flex-start;
    gap: 16px;

    width: 728px;
    padding-top: 39px;
  }

  @media ${devices.desktop} {
    width: 975px;
    padding-top: 47px;
    gap: 24px;
  }
`

const PointsTitle = styled.h3`
  font-weight: 800;
  font-size: 16px;
  line-height: 23px;
  color: var(--color-blue-700);

  align-items: center;

  @media ${devices.tablet} {
    text-align: left;

    font-size: 26px;
  }

  @media ${devices.desktop} {
    font-size: 30px;
    line-height: 32px;
  }
`

const PointsRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 16px;

  @media ${devices.tablet} {
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;
  }

  @media ${devices.desktop} {
    gap: 90px;
  }
`

const PointsColumn = styled.ul`
  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 16px;

  @media ${devices.tablet} {
    align-items: flex-start;
  }
`

const Benefits = () => {
  const { t } = useTranslation(["page-careers", "common"])

  return (
    <Container>
      <TitleContainer>
        <Title>{t("common:lbl_careers")}</Title>
        <Subtitle>{t("txt_careers_subtitle")}</Subtitle>
      </TitleContainer>
      <CoverContainer>
        <CoverImageBackground
          light={assetUrl(
            "/comps/benefits-backgroud-light.svg"
          )}
          dark={assetUrl(
            "/comps/benefits-backgroud-dark.svg"
          )}
        />
        <CoverImageForeground
          source={"/comps/benefits-foreground"}
          format="svg"
        />
        <DesktopOrTablet>
          <PointsAligner>
            <PointsContainer>
              <PointsTitle>
                {t("txt_our_benefit")}
              </PointsTitle>
              <PointsRow>
                <PointsColumn>
                  <Point text="txt_medical_insurance" />
                  <Point text="txt_salary_review" />
                  <Point text="txt_free_parking" />
                </PointsColumn>
                <PointsColumn>
                  <Point text="txt_fun_activities" />
                  <Point text="txt_flexible_schedule" />
                  <Point text="txt_stock_plan" />
                </PointsColumn>
              </PointsRow>
            </PointsContainer>
          </PointsAligner>
        </DesktopOrTablet>
      </CoverContainer>

      <Mobile>
        <PointsContainer>
          <PointsTitle>{t("txt_our_benefit")}</PointsTitle>
          <PointsRow>
            <PointsColumn>
              <Point text="txt_medical_insurance" />
              <Point text="txt_salary_review" />
              <Point text="txt_free_parking" />
            </PointsColumn>
            <PointsColumn>
              <Point text="txt_fun_activities" />
              <Point text="txt_flexible_schedule" />
              <Point text="txt_stock_plan" />
            </PointsColumn>
          </PointsRow>
        </PointsContainer>
      </Mobile>
    </Container>
  )
}

export default Benefits
